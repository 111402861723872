*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible !important;
}

.static {
  position: static !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.right-0 {
  right: 0px !important;
}

.right-10 {
  right: 2.5rem !important;
}

.bottom-10 {
  bottom: 2.5rem !important;
}

.left-0 {
  left: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.m-0\.5 {
  margin: 0.125rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.flex {
  display: flex !important;
}

.table {
  display: table !important;
}

.hidden {
  display: none !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-36 {
  height: 9rem !important;
}

.h-full {
  height: 100% !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-36 {
  width: 9rem !important;
}

.w-full {
  width: 100% !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.items-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.bg-blue-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05 !important;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
}

.from-gray-50 {
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.via-indigo-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.to-indigo-100 {
  --tw-gradient-to: #e0e7ff !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.font-medium {
  font-weight: 500 !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.text-yellow-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .md\:w-96 {
    width: 24rem !important;
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

